import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import {
    getCMSObject,
    MyDrawingEntries,
    MyPoints,
    MyTicketHistory,
    MyRewardsHistory,
    ErrorBoundary,
    TeleScript,
    MyPointsForDrawing,
    DisplayContent,
} from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import MyAllocatableEntries from '../Components/MyActivity/MyAllocatableEntries';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import UserLockout from '../utils/user_lockout';
import siteConfig from '../promotionConfig';

const MyActivityPage = ({ reward, activity, points, winner, user, config, cmsSourceFirebase, actions, match, page, pointsForDrawings }) => {
    const myAllocatableTelescript = getCMSObject('data.components.teleScripts.myAllocatableEntries.jsonBlock');
    const myPointsForDrawingTelescript = getCMSObject('data.components.teleScripts.myPointsForDrawing.jsonBlock');
    const myDrawingEntriesTelescript = getCMSObject('data.components.teleScripts.myDrawingEntries.jsonBlock');
    const myPointsTelescript = getCMSObject('data.components.teleScripts.myPointsHistory.jsonBlock');
    const myTicketHistoryTelescript = getCMSObject('data.components.teleScripts.myTicketHistory.jsonBlock');
    const myRewardsHistoryTelescript = getCMSObject('data.components.teleScripts.myRewardsHistory.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <UserLockout>
                <Container>
                    <ErrorBoundary fallback={<TeleScript line={myAllocatableTelescript?.fallback} />} details={{ page, user, config, reward }}>
                        <MyAllocatableEntries telescript={myAllocatableTelescript} />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<TeleScript line={myDrawingEntriesTelescript?.fallback} />} details={{ page, user, config, reward }}>
                        <MyDrawingEntries
                            winner={winner}
                            actions={actions}
                            telescript={myDrawingEntriesTelescript}
                            WinnerLink={WinnerLinkWrapper}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                hash: 'drawing-entries',
                                pageSize: siteConfig?.components?.drawingEntries?.defaultPageSize ?? 5,
                                promotionDetailsLink: '/second-chance-promotion',
                            }}
                        />
                    </ErrorBoundary>
                    <DisplayContent isVisible={config?.web?.features?.pointsForDrawings}>
                        <ErrorBoundary fallback={<TeleScript line={myPointsForDrawingTelescript?.fallback} />} details={{ page, user, config, reward }}>
                            <MyPointsForDrawing
                                pointsHistory={pointsForDrawings?.playerPFDTransactions}
                                actions={actions}
                                telescript={myPointsForDrawingTelescript}
                                options={{
                                    variant: 'theme-primary',
                                    hasCollapser: true,
                                    hash: 'points-drawing',
                                    pageSize: siteConfig?.components?.pointsHistory?.defaultPageSize ?? 5,
                                    linkOlderDrawingsTarget: myPointsForDrawingTelescript?.linkOlderDrawingsTarget ?? `_self`,
                                    linkOlderDrawings: myPointsForDrawingTelescript?.linkOlderDrawingsUrl ?? `${config.config.store_uri}/users/drawing_orders.php`,
                                }}
                            />
                        </ErrorBoundary>
                    </DisplayContent>
                    <ErrorBoundary fallback={<TeleScript line={myPointsTelescript?.fallback} />} details={{ page, user, config, reward }}>
                        <MyPoints
                            points={points}
                            actions={actions}
                            telescript={myPointsTelescript}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                hash: 'points-history',
                                pageSize: siteConfig?.components?.pointHistory?.defaultPageSize ?? 5,
                            }}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<TeleScript line={myTicketHistoryTelescript?.fallback} />} details={{ page, user, config, reward }}>
                        <MyTicketHistory
                            activity={activity}
                            actions={actions}
                            telescript={myTicketHistoryTelescript}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                hash: 'ticket-history',
                                pageSize: siteConfig?.components?.ticketHistory?.defaultPageSize ?? 5,
                                promotionsLink: '/promotions',
                            }}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<TeleScript line={myRewardsHistoryTelescript?.fallback} />} details={{ page, user, config, reward }}>
                        <MyRewardsHistory
                            reward={reward}
                            actions={actions}
                            telescript={myRewardsHistoryTelescript}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                hash: 'ticket-history',
                                pageSize: siteConfig?.components?.rewardsHistory?.defaultPageSize ?? 5,
                                promotionsLink: '/promotions',
                            }}
                            resolveCurrency={siteConfig.resolveCurrency}
                        />
                    </ErrorBoundary>
                </Container>
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivityPage);
